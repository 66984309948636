header{
    color: white;
    .admin-logo{
        font-size: 1.5rem;
    }
    a{
        color: rgb(163, 163, 163) !important;
        transition: .3s;
        &:hover{
            color: rgb(234, 234, 234) !important;
        }
    }
}